import React from 'react'
import DiscoverBanner from './img/Images/hm-bt-img.webp'
import ExcellBan from './img/Projects/Perth Airport/008.webp';
import AbtBanner from './img/Images/homeBan01.webp';
import FocusBan from './img/Images/homeBan02.webp';




function Welcome() {
  return (
    <>
    <div className='intro'><h1>Paneline - Simplicity is the ultimate sophistication</h1></div>
    <div className='parallax'></div>

    <div className='welcome-container-abt'>
    <div className='main-body'>
        <div className='acu-col-left'><img src={ExcellBan} alt="Acoustics"/></div>


        <div className='acu-col-right-abt2'><div className='acu-copy'><h1>Our Legacy</h1>

          <h3>Since 1983, Acoustics has been a part of the construction industry, earning a prominent reputation locally and nationally. Our legacy is defined by pioneering acoustic ceilings and accessories such as suspended ceiling framing and resilient mount systems. With our vast experience and expertise, we uphold an unwavering commitment to quality and service to all our clients and projects.</h3></div>
          </div>
          </div>

      </div>
      <div className='welcome-container2'>
      <div className='main-body'>
      <div className='welcome-col-left'><div className='welcome-copy'><h1>Our Focus </h1>

        <h3>At Acoustics, we embrace projects of all sizes, believing that none are too big or too small for our expertise. With over 35 years of experience in the construction industry, we've contributed to over 900 projects. From small commercial builds to regional schools, we uphold our core values of honesty, professionalism, top-quality craftsmanship and collaboration in every endeavor.</h3></div>
      </div>



      <div className='welcome-col-right'><img src={FocusBan} alt="welcome" /></div>
      </div>

    </div>
    <div className='welcome-container-abt'>
    <div className='main-body'>
    <div className='acu-col-left'><img src={AbtBanner} alt="Acoustics"/></div>


    <div className='acu-col-right-abt2'><div className='acu-copy'><h1>Our Promise</h1>

      <h3>Our promise is that each project receives the utmost care and attention to deliver excellence tailored to your needs.</h3></div>
      </div>

      </div>
  </div>




      <div className='welcome-container2'>
      <div className='main-body'>
        <div className='welcome-col-left'><div className='welcome-copy'><h1>Discover the outstanding benefits of Paneline architectural ceilings</h1>

          <h3>Paneline, our premier architectural ceiling product, delivers unparalleled durability, outstanding acoustic performance, and a refined, elegant aesthetic.</h3></div>
          <div className='line-btn'><a href="/Paneline">More Information</a></div></div>


        <div className='welcome-col-right'><img src={DiscoverBanner} alt="welcome" /></div>
        </div>

      </div>




 
    </>
  )
}

export default Welcome
