import React from 'react'
import './Footer.css';
import './Media-styles.css';
import Lin from './img/in.webp';
import Mail from './img/mail.webp';
import Phone from './img/phone.webp';
import Ft_Logo from './img/Logo/ft-logo.webp';



function Footer() {
  return (
    <div className='ft-main-container'>
    <div className='footer-container'>
      <div className='footer-col1'><a href="/"><img src={Ft_Logo} alt="Acoustics" /></a><div className="ft-info">For over 35 years, Acoustics has been dedicated to delivering exceptional quality products and services..</div></div>
      <div className='footer-col2'><h3 className="ft-col-tit">Find more</h3><ul>
      <li className='ft-col-list range-mob'><a href='/'>Home</a></li>
        <li className='ft-col-list range-desk'><a href='/Paneline/'>Paneline</a></li>
        <li className='ft-col-list range-desk'><a href='/ProRange/'>Portfolio</a></li>
        <li className='ft-col-list range-desk'><a href='/Contact-us/'>Contact us</a></li>
        
      </ul></div>



      <div className='footer-col3'><h3 className="ft-col-tit">Downloads</h3><ul>
        <li className='ft-col-list'><a href="https://www.acoustic.com.au/downloads/Acoustics_Paneline_Brochure.pdf" target="_blank" rel="noreferrer">Brochure</a></li>


        <li className='ft-col-list privacy'><a href="http://acoustic.com.au/downloads/Acoustics_Paneline_Installation_Guide.pdf" target="_blank" rel="noreferrer">Installation Guide</a></li>
        <li className='ft-col-list privacy'><a href="http://acoustic.com.au/downloads/Acoustics_Paneline_Data_sheet.pdf" target="_blank" rel="noreferrer">Data Sheet</a></li>
        <li className='ft-col-list privacy'><a href="http://acoustic.com.au/downloads/Tests/Certificate_of_Test_BlueScope_Zincalume.pdf" target="_blank" rel="noreferrer">Acoustic Testing</a></li>
        <li className='ft-col-list privacy'><a href="/">Fire Certificate</a></li>
        <li className='ft-col-list privacy'><a href="http://acoustic.com.au/downloads/Warranty/Paneline%20General%20Warranty_SAMPLE.pdf" target="_blank" rel="noreferrer">Warranty</a></li>
        
      </ul></div>
      <div className='footer-col4'><h3 className="ft-col-tit">Contact us</h3>
        <ul>
          
          <li className='social'><a href="https://www.linkedin.com/company/104802238" target='_blank' rel="noreferrer"><img src={Lin} alt="Acoustic" /></a></li>
          <li className='social'><a href="mailto:enqueries@acoustics.com.au"><img src={Mail} alt="Acoustic" /></a></li>
          <li className='social'><a href="tel:08 6156 0444"><img src={Phone} alt="Acoustic" /></a></li>
        </ul>
        <div className="ft-info ft-adrs">107 Mulgul Road, Malaga, Perth, WA, 6090</div>
      </div>
    </div>
    </div>
  )
}

export default Footer;